import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AuthLogout = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/AuthLogout"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerClient"))
);

// ==============================|| MAIN ROUTING ||============================== //
const login = sessionStorage.getItem("uid");
const MainRoutes = {
  path: "/",
  element: login ? <MainLayout /> : <AuthLogin3 />,
  children: [
    {
      path: "/home",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "client",
      element: <UtilsTablerIcons />,
    },
    {
      path: "logout",
      element: <AuthLogout />,
    },
  ],
};

export default MainRoutes;
