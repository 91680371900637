// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUser } from '@tabler/icons';

import { useEffect } from 'react';

import axios from 'axios';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconUser
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'icons',
            title: sessionStorage.getItem('full name'),
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Logout',
                    type: 'item',
                    url: '/logout',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default utilities;
